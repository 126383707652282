<template>
    <el-dialog
        :close-on-click-modal="false"
        :custom-class="showViewer ? 'viewer-top-padding-decrease' : ''"
        style="padding: 1em 20em; height: 100%;"
        :fullscreen="true"
        :visible="visible"
        :title="setTitle()"
        @close="resetForm"
        :before-close="handleClose"
    >
        <el-tabs v-loading="isModalLoading" v-model="activeTab">
            <el-tab-pane label="Svenska" name="first">
                <el-form :rules="rules" v-if="!showViewer" label-position="top" ref="questionForm" :model="form">
                    <el-form-item prop="questionSe" id="form-item-label-relative" label="Fråga">
                        <el-input v-model="form.questionSe" />
                        <el-popover
                            :tabindex="-1"
                            placement="top-start"
                            title="Fråga: Rubrik"
                            width="600"
                            trigger="hover"
                            content="Här skapar du namnet på frågan och är den rubrik som kommer visas i listan av frågor i app"
                        >
                            <i slot="reference" style="position: absolute; left: 3rem; top: -2rem" class="fas fa-info-circle" />
                        </el-popover>
                    </el-form-item>
                    <el-form-item prop="answerSe" id="form-item-label-relative" label="* Svar">
                        <editor
                            @load="onEditorLoad"
                            :options="editorOptions"
                            @change="onEditorChange"
                            ref="tuiEditor"
                            v-model="form.answerSe"
                            preview-style="vertical"
                            :initial-value="editorInstance"
                            height="400px"
                            initial-edit-type="wysiwyg"
                        />
                        <el-popover
                            :tabindex="-1"
                            placement="top-start"
                            title="Formaterat svar på fråga"
                            width="600"
                            trigger="hover"
                            content="Här skapar du svaret till din fråga, här skapar du även den formateringen som kommer att synas i app, med hjälp av verktygfältet ovanför textrutan kan du välja t.ex att lägga till bilder, länkar, text formateringar. Går man sedan in på en fråga i app är det denna formateringen som kommer att synas för användaren. Vill du förhandsgranska hur formateringen ser ut kan du klicka på 'förhandsgranska' knappen nere i högra hörnet om textrutan"
                        >
                            <i slot="reference" style="position: absolute; left: 2.5rem; top: -2rem" class="fas fa-info-circle" />
                        </el-popover>
                    </el-form-item>
                    <el-button @click="showViewer = true" type="primary" class="mt-3 mb-4" icon="el-icon-view">Förhandsgranska svar</el-button>
                    <el-form-item label="Taggar">
                        <Tags @updateTags="updateTags" :dynamic-tags="selectedTags" />
                        <el-popover
                            :tabindex="-1"
                            placement="top-start"
                            title="Taggar"
                            width="600"
                            trigger="hover"
                            content="Här kan du sätta taggar för följande fråga för att lättare kunna organisera frågorna utifrån ämne t.ex app, fakturering, skatt och så vidare. Detta gör även taggarna sökbara i app"
                        >
                            <i slot="reference" style="position: absolute; left: 2.9rem; top: -2rem" class="fas fa-info-circle" />
                        </el-popover>
                    </el-form-item>

                    <el-form-item prop="companyType" id="form-item-label-relative" label="* Företagstyp">
                        <el-select multiple label="Företagstyp" v-model="form.companyTypes" placeholder="Välj företagstyp">
                            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.label" />
                        </el-select>
                        <el-popover
                            :tabindex="-1"
                            placement="top-start"
                            title="Företagstyp"
                            width="600"
                            trigger="hover"
                            content="Här kan du välja för vilka företagstyper denna fråga ska ses för i app, om det är så att informationen endast gäller för en företagstyp. Du kan även välja båda företagstyperna om det gäller för båda. "
                        >
                            <i slot="reference" style="position: absolute; left: 5.5rem; top: -2rem" class="fas fa-info-circle" />
                        </el-popover>
                    </el-form-item>
                    <el-form-item prop="category" id="form-item-label-relative" label="* Kategori">
                        <el-select multiple v-model="form.categories" placeholder="Välj kategori" value-key="id">
                            <el-option v-for="item in categoryOptions" :key="item.id" :label="item.nameSe" :value="{ id: item.id, name: item.nameSe }" />
                        </el-select>
                        <el-popover
                            :tabindex="-1"
                            placement="top-start"
                            title="Kategori"
                            width="600"
                            trigger="hover"
                            content="Här kategoriserar du frågan, beroende på vad frågan handlar om, t.ex 'Faktura' eller 'Kvitton' och så vidare, detta reflekteras sedan för att användaren i app i form utav en filteringen av frågor utifrån vald kategori"
                        >
                            <i slot="reference" style="position: absolute; left: 4.2rem; top: -2rem" class="fas fa-info-circle" />
                        </el-popover>
                    </el-form-item>
                    <el-form-item prop="everyYear" id="form-item-label-relative" label="Kontrolleras varje år">
                        <el-radio-group v-model="form.everyYear">
                            <el-radio :label="true">Ja</el-radio>
                            <el-radio :label="false">Nej</el-radio>
                        </el-radio-group>

                        <el-popover
                            :tabindex="-1"
                            placement="top-start"
                            title="Uppdateras varje år"
                            width="600"
                            trigger="hover"
                            content="Här kan du välja om frågan är någonting som kommer kontinuerligt uppdateras varje år eller inte, beroende på om informationen i frågan kan skilja sig från år till år."
                        >
                            <i slot="reference" style="position: absolute; left: 9rem; top: -2rem" class="fas fa-info-circle" />
                        </el-popover>
                    </el-form-item>
                    <!-- <el-form-item id="form-item-label-relative" label="Relaterat till fråga">
                        <el-select v-model="form.related" multiple filterable remote reserve-keyword placeholder="Sök och välj fråga" :remote-method="remoteMethod" :loading="loading">
                            <el-option v-for="question in options" :key="question.value" :label="question.label" :value="question.value" />
                        </el-select>
                        <el-popover
                            placement="top-start"
                            title="Relatera frågor"
                            width="600"
                            trigger="hover"
                            content="Här kan du relatera frågor till en existerande fråga, sök i fältet så kan du välja en eller flera frågor som är relaterad till en annan t.ex 'hur skapar man ett kvitto?' och 'hur redigerar man ett kvitto?' skulle du kunna relatera till varandra, I app kommer du sedan att se dessa relateringar till varandra längst ner på sidan om du är inne på en fråga som är relaterad till en annan"
                        >
                            <i slot="reference" style="position: absolute; left: 7.4rem; top: -2rem;" class="fas fa-info-circle" />
                        </el-popover>
                    </el-form-item> -->
                    <el-form-item id="form-item-label-relative" label="Publicering">
                        <el-checkbox v-model="form.isPublished">Publicerad i app</el-checkbox>
                        <el-popover
                            placement="top-start"
                            title="Publicering i app"
                            width="600"
                            trigger="hover"
                            content="Här väljer du om denna fråga ska komma att synas i app eller inte, om du vill att frågan ska vara synlig för användaren i app så bocka i rutan, om inte så låt rutan vara tom."
                        >
                            <i slot="reference" style="position: absolute; left: 4.8rem; top: -2rem" class="fas fa-info-circle" />
                        </el-popover>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="Engelska" name="second">
                <el-form :rules="rulesEn" class="relative" v-if="!showViewer" label-position="top" ref="questionFormEn" :model="form">
                    <el-form-item prop="questionEn" label="Question">
                        <el-input v-model="form.questionEn" />
                    </el-form-item>
                    <el-form-item prop="answerEn" label="* Answer">
                        <editor
                            @load="onEditorLoad"
                            @change="onEditorChange"
                            :options="editorOptions"
                            ref="tuiEditorEng"
                            v-model="form.answerEn"
                            preview-style="vertical"
                            :initial-value="editorInstanceEng"
                            height="400px"
                            initial-edit-type="wysiwyg"
                        />
                    </el-form-item>
                    <el-button @click="showViewer = true" type="primary" class="mt-3 mb-4" icon="el-icon-view">Förhandsgranska svar</el-button>
                    <el-form-item label="Tags">
                        <Tags class="testing" @updateTags="updateTags" :dynamic-tags="selectedTagsEng" />
                        <el-popover
                            :tabindex="-1"
                            placement="top-start"
                            title="Taggar"
                            width="600"
                            trigger="hover"
                            content="Här kan du sätta taggar för följande fråga för att lättare kunna organisera frågorna utifrån ämne t.ex app, fakturering, skatt och så vidare. Detta gör även taggarna sökbara i app"
                        >
                            <i slot="reference" style="position: absolute; left: 2rem; top: -1.9rem" class="fas fa-info-circle" />
                        </el-popover>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <div v-if="showViewer">
            <el-button class="mb-3" type="primary" icon="el-icon-arrow-left" @click="showViewer = false">Gå tillbaka till fråga</el-button>
            <AnswerViewer :visible="showViewer" :answer="activeTab === 'first' ? editorInstance : editorInstanceEng" @close="showViewer = false" :use-dialog="false" />
        </div>
        <div v-if="!showViewer" :style="{ height: activeTab === 'first' ? 'inherit' : '100px' }" class="sticky right-0 bottom-0 w-full flex justify-end items-end pb-2">
            <el-button @click="handleClose">Avbryt</el-button>
            <el-button v-if="activeTab === 'first'" type="primary" @click="activeTab = 'second'">Nästa Steg: Engelsk version <i class="el-icon-arrow-right"/></el-button>
            <el-button v-else type="primary" @click="submitForm()">Spara</el-button>
        </div>
    </el-dialog>
</template>
<script>
import Tags from "../../accountCategories/components/Tags.vue";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/vue-editor";
import AnswerViewer from "./AnswerViewer.vue";
import Api from "../faq.api.js";
export default {
    components: {
        Tags,
        Editor,
        AnswerViewer,
    },
    props: {
        isModalLoading: {
            type: Boolean,
            default: false,
        },
        editing: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        allQuestions: {
            type: Array,
            default: () => [],
        },
        question: {
            type: Object,
            default: () => ({
                questionSe: "",
                questionEn: "",
                answerSe: "",
                answerEn: "",
                categories: [],
                everyYear: null,
                companyTypes: [],
                tags: [],
                questionAndAnswerId: undefined,
            }),
        },
    },
    async created() {
        const categories = await Api.getCategories();
        this.categoryOptions = categories;
    },
    data() {
        var validateAnswers = (rule, value, callback) => {
            // If isPublished is false, allow empty answers (admin can answer them later)
            if (this.form.isPublished === false) {
                callback();
                return;
            }
            // Otherwise, answers are required (immediately available to users)
            rule.field === "answerSe" ? (value = this.editorInstance) : (value = this.editorInstanceEng);
            if (rule.field === "answerSe" && value === "") {
                this.activeTab = "first";
                callback(new Error("Svar är obligatorisk"));
            } else if (rule.field === "answerEn" && value === "") {
                callback(new Error("Svar är obligatorisk"));
            } else {
                callback();
            }
        };
        var validateSelectDropdown = (rule, value, callback) => {
            value = this.form.companyTypes;
            rule.field === "companyType" ? (value = this.form.companyTypes) : (value = this.form.categories);
            if (value.length == []) {
                this.activeTab = "first";
                rule.field === "companyType" ? callback(new Error("Företagstyp är obligatorisk")) : callback(new Error("Kategori är obligatorisk"));
            } else {
                callback();
            }
        };
        return {
            editorOptions: {
                autofocus: false,
                toolbarItems: [["heading", "bold", "italic", "strike"], ["hr", "quote"], ["ul", "ol", "indent", "outdent"], ["table", "image", "link"], ["scrollSync"]],
            },
            selectedCategories: [],
            activeTab: "first",
            showViewer: false,
            options: [],
            list: [],
            questionsList: this.allQuestions,
            loading: false,
            editorInstance: "",
            editorInstanceEng: "",
            selectedTags: [],
            selectedTagsEng: [],
            categoryOptions: [],
            form: {
                questionSe: "",
                questionEn: "",
                answerSe: "",
                answerEn: "",
                categories: [],
                everyYear: null,
                companyTypes: [],
                tags: [],
                tagsEng: [],
                questionAndAnswerId: undefined,
                isPublished: false,
            },
            typeOptions: [
                {
                    value: 1,
                    label: "Enskild firma",
                },
                {
                    value: 2,
                    label: "Aktiebolag",
                },
                {
                    value: 3,
                    label: "Handelsbolag",
                },
                {
                    value: 4,
                    label: "Kommanditbolag",
                },
                {
                    value: 5,
                    label: "Ekonomisk förening",
                },
                {
                    value: 8,
                    label: "Bostadsrättsförening",
                },
            ],
            rules: {
                questionSe: [{ required: true, message: "Fråga är obligatorisk", trigger: "change" }],
                answerSe: [{ validator: validateAnswers, message: "Svar är obligatorisk", trigger: "change" }],
                companyType: [{ validator: validateSelectDropdown, message: "Företagstyp är obligatoriskt", trigger: "change" }],
                category: [{ validator: validateSelectDropdown, message: "Kategori är obligatoriskt", trigger: "change" }],
                everyYear: [{ required: true, message: "Vänligen välj ett alternativ", trigger: "change" }],
            },
            rulesEn: {
                questionEn: [{ required: true, message: "Fråga är obligatorisk", trigger: "change" }],
                answerEn: [{ validator: validateAnswers, message: "Svar är obligatorisk", trigger: "change" }],
            },
        };
    },
    watch: {
        question: {
            immediate: false,
            handler(newVal) {
                if (newVal) {
                    this.form = { ...newVal };
                    this.selectedTags = newVal.tags || [];
                    this.selectedTagsEng = newVal.tagsEng || [];
                    this.editorInstance = newVal.answerSe;
                    this.editorInstanceEng = newVal.answerEn;
                    if (this.$refs.tuiEditor || this.$refs.tuiEditorEng) {
                        this.$refs.tuiEditor.invoke("setMarkdown", newVal.answerSe);
                        this.$refs.tuiEditorEng.invoke("setMarkdown", newVal.answerEn);
                    }
                } else {
                    this.resetForm();
                }
            },
        },
    },
    mounted() {
        this.list = this.questionsList.map(item => {
            return {
                value: item.id,
                label: item.question,
            };
        });
        this.options = this.list;
    },
    methods: {
        onEditorLoad() {
            this.$nextTick(() => {
                const buttons = document.querySelectorAll(".toastui-editor-toolbar-group button");
                buttons.forEach(button => {
                    button.setAttribute("tabindex", "-1");
                });
            });
        },
        setTitle() {
            if (this.editing && !this.showViewer) {
                return "Redigera fråga";
            }
            return this.showViewer ? "Förhandsgranskning" : "Lägg till fråga";
        },
        onEditorChange() {
            const content = this.$refs.tuiEditor.invoke("getMarkdown");
            const contentEng = this.$refs.tuiEditorEng.invoke("getMarkdown");
            this.editorInstance = content;
            this.editorInstanceEng = contentEng;
            if (this.activeTab === "first" && this.editorInstance.length >= 1) {
                this.$refs.questionForm.validateField("answerSe");
            } else if (this.activeTab === "second" && this.editorInstanceEng.length >= 1) {
                this.$refs.questionFormEn.validateField("answerEn");
            }
        },
        remoteMethod(query) {
            if (query !== "") {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.options = this.list.filter(item => {
                        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                return this.options;
            }
        },
        updateTags(tags) {
            if (this.activeTab === "first") {
                this.selectedTags = tags;
                this.form.tags = this.selectedTags;
            } else {
                this.selectedTagsEng = tags;
                this.form.tagsEng = this.selectedTagsEng;
            }
        },
        resetForm() {
            this.editorInstance = "";
            this.editorInstanceEng = "";
            this.selectedTags = [];
            this.selectedTagsEng = [];
            this.activeTab = "first";
            this.form = {
                questionSe: "",
                questionEn: "",
                answerSe: "",
                answerEn: "",
                categories: [],
                everyYear: null,
                companyTypes: [],
                tags: [],
                tagsEng: [],
                questionAndAnswerId: undefined,
                isPublished: false,
            };
            if (this.$refs.tuiEditor && this.$refs.tuiEditorEng) {
                this.$refs.tuiEditor.invoke("setMarkdown", "");
                this.$refs.tuiEditorEng.invoke("setMarkdown", "");
            }
            if (this.$refs.questionForm && this.$refs.questionFormEn) {
                this.$refs.questionForm.resetFields();
                this.$refs.questionFormEn.resetFields();
            }
        },
        submitForm() {
            this.$refs.questionForm.validate(valid => {
                if (valid) {
                    this.$refs.questionFormEn.validate(valid => {
                        if (valid) {
                            this.saveQuestion();
                        }
                    });
                }
            });
        },
        saveQuestion() {
            this.editorInstance = this.$refs.tuiEditor.invoke("getMarkdown");
            this.editorInstanceEng = this.$refs.tuiEditorEng.invoke("getMarkdown");
            this.form.tags.push(...(this.form.tagsEng || []));
            const formData = { ...this.form, answerSe: this.editorInstance, answerEn: this.editorInstanceEng };
            if (formData) {
                this.$emit("save", formData);
            }
            this.resetForm();
        },
        handleClose() {
            this.$emit("close");
            this.showViewer = false;
        },
    },
};
</script>

<style>
@import url("https://use.typekit.net/otv3cvl.css");
#form-item-label-relative .el-form-item__label {
    position: relative;
}

.testing {
    z-index: 9999999;
}

.viewer-top-padding-decrease .el-dialog__body {
    padding: 5px 20px;
}
.toastui-editor-contents {
    p,
    blockquote,
    pre,
    del,
    table,
    strong,
    em {
        color: #415770;
        font-family: "futura-pt";
        font-size: 16px;
    }
    ol > li::before {
        color: #415770 !important;
        font-weight: bold !important;
        font-size: 16px !important;
        padding-top: 2px !important;
    }
    ul > li::before {
        background-color: #415770 !important;
        font-weight: bold !important;
        font-size: 16px !important;
        margin-top: 10px !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        border-bottom: none !important;
        font-family: "futura-pt";
        color: #415770;
    }
    table th {
        background-color: white !important;
        color: #415770 !important;
    }
}

.toastui-editor-contents th p {
    color: #415770 !important;
    font-weight: bold !important;
}
.toastui-editor-contents td {
    vertical-align: middle !important;
}
</style>
